import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'
import styled from 'styled-components'

interface ConfirmDialogProps {
  open: boolean
  headerText: string
  contentTitle?: string
  message: string
  onConfirm: () => void
  onCancel: () => void
}

export default function ConfirmDialog({ 
  open, 
  headerText, 
  contentTitle, 
  message, 
  onConfirm, 
  onCancel 
}: ConfirmDialogProps) {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{headerText}</DialogTitle>
      <DialogContent>
        {contentTitle && <ArticleTitle>{contentTitle}</ArticleTitle>}
        {message}
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px', gap: '16px' }}>
        <Button 
          onClick={onConfirm} 
          variant="outlined"
        >
          Potwierdź
        </Button>
        <Button 
          onClick={onCancel} 
          variant="contained" 
          color="error"
        >
          Anuluj
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const ArticleTitle = styled.div`
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.secondary};
` 