import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { supabase } from 'utils/supabase'
import styled from 'styled-components'
import { Button } from '@mui/material'
import toast from 'react-hot-toast'
import ConfirmDialog from 'components/ConfirmDialog'
import dayjs from 'dayjs'
import 'dayjs/locale/pl'  // Import Polish locale

interface Article {
  id: number
  date: string
  title: string
  image: string
  content: string
  linkName: string
  link: string
}

const ArticlesList = () => {
  const [articles, setArticles] = useState<Article[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    fetchArticles()
  }, [])

  const fetchArticles = async () => {
    console.log('=== FETCHING ARTICLES ===', new Date().toISOString())
    
    const { data, error } = await supabase
      .from('fundacja')
      .select('*')

    if (error) {
      console.error('Error fetching articles:', error)
      return
    }

    console.log('=== ARTICLES BEFORE SORTING ===')
    console.table(data?.map(a => ({ id: a.id, date: a.date, title: a.title })))

    const monthMap: { [key: string]: number } = {
      'stycznia': 0, 'lutego': 1, 'marca': 2, 'kwietnia': 3,
      'maja': 4, 'czerwca': 5, 'lipca': 6, 'sierpnia': 7,
      'września': 8, 'października': 9, 'listopada': 10, 'grudnia': 11
    }

    // Sort articles by date (newest first)
    const sortedArticles = [...(data || [])].sort((a, b) => {
      const parseDate = (dateStr: string) => {
        try {
          const parts = dateStr.split(' ')
          const day = parseInt(parts[0])
          const monthNum = monthMap[parts[1]?.toLowerCase()]
          const year = parseInt(parts[2])
          
          if (isNaN(day) || monthNum === undefined || isNaN(year)) {
            return 0
          }
          
          return Date.UTC(year, monthNum, day)
        } catch (error) {
          console.error('Error parsing date:', dateStr, error)
          return 0
        }
      }

      const timestampA = parseDate(a.date)
      const timestampB = parseDate(b.date)

      return timestampB - timestampA
    })

    console.log('=== ARTICLES AFTER SORTING ===')
    console.table(sortedArticles.map(a => ({ id: a.id, date: a.date, title: a.title })))
    
    setArticles(sortedArticles)
  }

  const handleDelete = async (id: number) => {
    const article = articles.find(a => a.id === id)
    if (!article) return

    try {
      // If article has an image, delete it from storage
      if (article.image) {
        const imagePath = article.image.split('/').pop() // Get filename from URL
        if (imagePath) {
          await supabase.storage
            .from('articles')
            .remove([imagePath])
        }
      }

      // Delete the article from the database
      const { error } = await supabase
        .from('fundacja')
        .delete()
        .eq('id', id)

      if (error) throw error

      toast.success('Artykuł został usunięty')
      fetchArticles()
    } catch (error) {
      toast.error('Błąd podczas usuwania artykułu')
      console.error('Error deleting article:', error)
    }
  }

  // Add state for confirmation dialog
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [articleToDelete, setArticleToDelete] = useState<number | null>(null)

  const handleDeleteClick = (id: number) => {
    setArticleToDelete(id)
    setDeleteDialogOpen(true)
  }

  const handleDeleteConfirm = async () => {
    if (articleToDelete) {
      await handleDelete(articleToDelete)
    }
    setDeleteDialogOpen(false)
    setArticleToDelete(null)
  }

  const truncateContent = (content: string) => {
    // Remove HTML tags and get plain text
    const plainText = content.replace(/<[^>]*>/g, '')
    return plainText.length > 150 ? plainText.slice(0, 150) + '...' : plainText
  }

  return (
    <Wrapper>
      <Header>
        <Title>Artykuły</Title>
        <Button 
          variant="contained" 
          onClick={() => navigate('/admin/articles/new')}
        >
          Dodaj artykuł
        </Button>
      </Header>
      <ArticlesGrid>
        {articles.map(article => (
          <ArticleCard key={article.id}>
            {article.image && (
              <ArticleImage>
                <img src={article.image} alt={article.title} />
              </ArticleImage>
            )}
            <ArticleContent>
              <ArticleDate>{article.date}</ArticleDate>
              <ArticleTitle>{article.title}</ArticleTitle>
              <ArticleExcerpt>
                {truncateContent(article.content)}
              </ArticleExcerpt>
              {article.linkName && (
                <ArticleLink href={article.link} target="_blank">
                  {article.linkName}
                </ArticleLink>
              )}
            </ArticleContent>
            <ButtonsGroup>
              <Button 
                variant="outlined"
                size="small"
                onClick={() => navigate(`/admin/articles/edit/${article.id}`)}
              >
                Edytuj
              </Button>
              <Button 
                variant="outlined"
                color="error"
                size="small"
                onClick={() => handleDeleteClick(article.id)}
              >
                Usuń
              </Button>
            </ButtonsGroup>
          </ArticleCard>
        ))}
      </ArticlesGrid>
      <ConfirmDialog
        open={deleteDialogOpen}
        headerText="Usuwasz:"
        contentTitle={articleToDelete ? articles.find(a => a.id === articleToDelete)?.title || '' : ''}
        message="Czy na pewno chcesz usunąć ten artykuł? Tej operacji nie można cofnąć."
        onConfirm={handleDeleteConfirm}
        onCancel={() => setDeleteDialogOpen(false)}
      />
    </Wrapper>
  )
}

export default ArticlesList

const Wrapper = styled.div`
  background: #f9f9f9;
  min-height: 100vh;
  max-width: 1800px;
  margin: 0 auto;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  background: #f9f9f9;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
`

const Title = styled.h1`
  margin: 0;
  font-size: 24px;
`

const ArticlesGrid = styled.div`
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(3, minmax(0, 0.9fr));
  padding: 20px 40px;
  margin: 0 auto;
  width: 95%;

  @media (max-width: 1400px) {
    grid-template-columns: repeat(2, 0.9fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 0.9fr;
  }
`

const ArticleCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  border: 1px solid #eee;
  border-radius: 8px;
  background: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: box-shadow 0.2s;
  height: 100%;
  position: relative;

  &:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
`

const ArticleTitle = styled.h2`
  margin: 0 0 10px;
  font-size: 18px;
  font-weight: 500;
  color: #333;
`

const ArticleDate = styled.div`
  color: #666;
  font-size: 14px;
  margin-bottom: 15px;
`

const ButtonsGroup = styled.div`
  display: flex;
  gap: 10px;
  padding: 15px;
  border-top: 1px solid #eee;
  margin-top: auto;
`

const ArticleImage = styled.div`
  width: 100%;
  height: 200px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const ArticleContent = styled.div`
  flex: 1;
  padding: 15px;
  display: flex;
  flex-direction: column;
`

const ArticleExcerpt = styled.div`
  color: #666;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const ArticleLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  text-decoration: none;
  display: block;
  margin-top: 10px;

  &:hover {
    text-decoration: underline;
  }
` 