import { useState, FormEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { supabase } from 'utils/supabase'
import styled from 'styled-components'
import { Button, TextField } from '@mui/material'
import dayjs from 'dayjs'
import 'dayjs/locale/pl'  // Import Polish locale
import toast from 'react-hot-toast'
import { v4 as uuidv4 } from 'uuid'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

interface ArticleForm {
  date: string
  title: string
  content: string
  linkName: string
  link: string
  image: File | null
}

const defaultValues: ArticleForm = {
  date: dayjs().locale('pl').format('D MMMM YYYY'),  // Today's date in Polish format
  title: '',
  content: '',
  linkName: '',
  link: '',
  image: null
}

// Add Quill modules configuration
const quillModules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    ['link', 'clean']
  ]
}

// Add the same helper function
const formatDateForInput = (dateStr: string): string => {
  try {
    // If it's a new article with today's date already in Polish format
    if (dateStr === defaultValues.date) {
      return dayjs().format('YYYY-MM-DD')
    }
    
    // For existing dates in Polish format (e.g., "20 listopada 2023")
    const date = dayjs(dateStr, 'D MMMM YYYY', 'pl')
    return date.format('YYYY-MM-DD')
  } catch (error) {
    console.error('Date parsing error:', error)
    return dayjs().format('YYYY-MM-DD')
  }
}

export default function ArticleForm() {
  const [values, setValues] = useState<ArticleForm>(defaultValues)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleValueChange = (name: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValues(prev => ({ ...prev, [name]: e.target.value }))
  }

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setValues(prev => ({ ...prev, image: e.target.files![0] }))
    }
  }

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Get the raw YYYY-MM-DD value from input
    const rawDate = e.target.value
    console.log('Raw date:', rawDate) // Debug

    // Create a dayjs object and format to Polish
    const selectedDate = dayjs(rawDate)
    const formattedDate = selectedDate.locale('pl').format('D MMMM YYYY')
    console.log('Formatted date:', formattedDate) // Debug
    
    setValues(prev => ({ ...prev, date: formattedDate }))
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    try {
      let imageUrl = ''
      
      // Handle image upload if an image was selected
      if (values.image) {
        const fileExt = values.image.name.split('.').pop()
        const fileName = `${uuidv4()}.${fileExt}`
        
        const { error: uploadError } = await supabase.storage
          .from('articles')
          .upload(fileName, values.image)

        if (uploadError) throw uploadError

        const { data: { publicUrl } } = supabase.storage
          .from('articles')
          .getPublicUrl(fileName)

        imageUrl = publicUrl
      }

      // Create new article
      const { error: insertError } = await supabase
        .from('fundacja')
        .insert([{
          id: Date.now(),
          date: values.date,
          title: values.title,
          content: values.content,
          linkName: values.linkName,
          link: values.link,
          image: imageUrl
        }])

      if (insertError) throw insertError

      toast.success('Artykuł został dodany')
      navigate('/admin/articles')
    } catch (error) {
      toast.error('Błąd podczas dodawania artykułu')
      console.error('Error adding article:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Wrapper>
      <Form id="articleForm" onSubmit={handleSubmit}>
        <Header>
          <Title>Nowy artykuł</Title>
          <ButtonsGroup>
            <Button 
              variant="contained" 
              type="submit"
              form="articleForm"  // Connect to form by ID
              disabled={loading}
            >
              {loading ? 'Dodawanie...' : 'Dodaj artykuł'}
            </Button>
            <Button 
              variant="outlined" 
              onClick={() => navigate('/admin/articles')}
            >
              Powrót
            </Button>
          </ButtonsGroup>
        </Header>

        <FormContent>
          <TextField
            required
            fullWidth
            label="Data"
            value={values.date}
            onChange={handleValueChange('date')}
            placeholder="np. 20 listopada 2024"
          />
          
          <TextField
            required
            fullWidth
            label="Tytuł"
            value={values.title}
            onChange={handleValueChange('title')}
          />

          <QuillWrapper>
            <label>Treść *</label>
            <ReactQuill
              value={values.content}
              onChange={(content) => setValues(prev => ({ ...prev, content }))}
              modules={quillModules}
              theme="snow"
              placeholder="Wpisz treść artykułu..."
            />
          </QuillWrapper>

          <TextField
            fullWidth
            label="Nazwa linku"
            value={values.linkName}
            onChange={handleValueChange('linkName')}
            placeholder="np. Przeczytaj całość wywiadu"
          />

          <TextField
            fullWidth
            label="Link"
            value={values.link}
            onChange={handleValueChange('link')}
          />

          <ImageUploadWrapper>
            <label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: 'none' }}
                id="image-upload"
              />
              <Button variant="outlined" component="span">
                Dodaj zdjęcie
              </Button>
            </label>
            {values.image && <ImageName>{values.image.name}</ImageName>}
          </ImageUploadWrapper>
        </FormContent>
      </Form>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #f9f9f9;
  min-height: 100vh;
  max-width: 1800px;
  margin: 0 auto;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  background: #f9f9f9;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  width: 90%;
  margin: 0 auto;

  @media (min-width: 1024px) {
    min-width: 900px;
  }

  @media (max-width: 768px) {
    width: 95%;
    padding: 20px;
  }
`

const Title = styled.h1`
  font-size: 24px;
  margin: 0;
`

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 40px;
  width: 90%;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 20px 20px;
    width: 95%;
  }
`

const QuillWrapper = styled.div`
  .ql-container {
    min-height: 200px;
    font-size: 16px;
    background: transparent;
  }

  .ql-editor {
    min-height: 200px;
    font-family: inherit;
    background: white;
  }

  label {
    display: block;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 1rem;
  }

  .ql-toolbar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: white;
  }

  .ql-container {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`

const ImageUploadWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const ImageName = styled.span`
  color: #666;
  font-size: 14px;
`

const ButtonsGroup = styled.div`
  display: flex;
  gap: 10px;
` 