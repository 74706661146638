import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { supabase } from 'utils/supabase'
import dayjs from 'dayjs'
import 'dayjs/locale/pl'  // Import Polish locale

interface Article {
  id: number
  date: string
  title: string
  content: string
  image: string
  linkName: string
  link: string
}

const ArticlesList = () => {
  const [articles, setArticles] = useState<Article[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchArticles()
  }, [])

  const fetchArticles = async () => {
    try {
      const { data, error } = await supabase
        .from('fundacja')
        .select('*')

      if (error) throw error

      const monthMap: { [key: string]: number } = {
        'stycznia': 0, 'lutego': 1, 'marca': 2, 'kwietnia': 3,
        'maja': 4, 'czerwca': 5, 'lipca': 6, 'sierpnia': 7,
        'września': 8, 'października': 9, 'listopada': 10, 'grudnia': 11
      }

      // Sort articles by date (newest first)
      const sortedArticles = [...(data || [])].sort((a, b) => {
        const parseDate = (dateStr: string) => {
          try {
            const parts = dateStr.split(' ')
            const day = parseInt(parts[0])
            const monthNum = monthMap[parts[1]?.toLowerCase()]
            const year = parseInt(parts[2])
            
            if (isNaN(day) || monthNum === undefined || isNaN(year)) {
              return 0  // Return 0 for invalid dates
            }
            
            // Create a timestamp without timezone offset
            return Date.UTC(year, monthNum, day)
          } catch (error) {
            console.error('Error parsing date:', dateStr, error)
            return 0
          }
        }

        const timestampA = parseDate(a.date)
        const timestampB = parseDate(b.date)

        return timestampB - timestampA
      })

      setArticles(sortedArticles)
    } catch (error) {
      console.error('Error fetching articles:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Wrapper id="aktualnosci">
      <TitleText>Aktualności</TitleText>
      {articles.map(article => (
        <ArticleCard key={article.id}>
          <ArticleDate>{article.date}</ArticleDate>
          <ArticleTitle>{article.title}</ArticleTitle>
          {article.image && (
            <ArticleImage>
              <img src={article.image} alt={article.title} />
            </ArticleImage>
          )}
          <ArticleText dangerouslySetInnerHTML={{ __html: article.content }} />
          {article.linkName && (
            <ArticleLink 
              href={article.link} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              {article.linkName}
            </ArticleLink>
          )}
        </ArticleCard>
      ))}
    </Wrapper>
  )
}

export default ArticlesList

const Wrapper = styled.div`
  width: 100%;
`

const TitleText = styled.div`
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 30px;
  color: ${({ theme }) => theme.colors.secondary};
`

const ArticleCard = styled.article`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
  margin-bottom: 40px;
`

const ArticleImage = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`

const ArticleTitle = styled.h2`
  margin: 0 0 20px;
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary};
`

const ArticleText = styled.div`
  color: #444;
  line-height: 1.6;
  font-size: 16px;
  
  p {
    margin-bottom: 1em;
  }

  ul, ol {
    margin-left: 1.5em;
    margin-bottom: 1em;
  }
`

const ArticleDate = styled.div`
  color: #666;
  font-size: 14px;
`

const ArticleLink = styled.a`
  display: inline-block;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
`
