import React from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from 'styled-components'
import { Theme } from 'utils/theme'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { AppCenterContextProvider } from 'AppContext'
import 'utils/global.css'
import { Toaster } from 'react-hot-toast'
import Cookies from 'components/Cookies'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.core.css'
import Landing from 'landing/Landing'
import Login from 'admin/login/Login'
import ArticlesList from 'admin/articles'
import ArticleForm from 'admin/articles/new'
import ProtectedRoute from 'components/ProtectedRoute'
import EditArticleForm from 'admin/articles/edit/index'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const router = createBrowserRouter([
  {
    path: '/',
    element: <Landing />
  },
  {
    path: '/admin',
    element: <Login />
  },
  {
    path: '/admin/articles',
    element: <ProtectedRoute><ArticlesList /></ProtectedRoute>
  },
  {
    path: '/admin/articles/new',
    element: <ProtectedRoute><ArticleForm /></ProtectedRoute>
  },
  {
    path: '/admin/articles/edit/:id',
    element: <ProtectedRoute><EditArticleForm /></ProtectedRoute>
  }
  // ... your other routes ...
])

root.render(
  <ThemeProvider theme={Theme}>
    <Toaster />
    <AppCenterContextProvider>
      <RouterProvider router={router} />
      <div id='modal-portal' />
      <Cookies />
    </AppCenterContextProvider>
  </ThemeProvider>
)
