import { createClient } from '@supabase/supabase-js'

const SUPABASE_URL = "https://uhzzqrcsjuzilbdkxmth.supabase.co"
const SUPABASE_KEY = process.env.REACT_APP_SUPABASE_KEY || ''

export const supabase = createClient(SUPABASE_URL, SUPABASE_KEY, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true
  }
})

export interface StorageFileResponse {
  publicURL: string;
  error?: Error;
}
